<template lang="pug">
  footer.footer
    .footer__container
      router-link.footer__logo.logo(:to="{ name: 'Home' }")
        img(
          :src="Logo"
          alt="logo"
        )
      .footer__copyright.copyright
        p © ГО «СК«ВІДАР» {{ new Date().getFullYear() }}. Всі права захищені.
      ul.footer__social.social
        li.social__item(
          v-for="(item, index) in social"
          :key="index"
        )
          a.social__link(:href='item.socialLink')
            img(
              :src='item.socialIcon'
              :alt='item.socialName'
            )
</template>

<script>
import Logo from '@/assets/images/black-logo.svg'
import TelegramIcon from '@/assets/images/social/telegram.svg'
import InstagramIcon from '@/assets/images/social/instagram-footer.svg'
import FacebookIcon from '@/assets/images/social/facebook-footer.svg'
export default {
  name: 'FooterBlock',
  data () {
    return {
      Logo,
      social: [
        {
          socialLink: 'https://t.me/vidarzp',
          socialIcon: TelegramIcon,
          socialName: 'telegram'
        },
        {
          socialLink: 'https://www.instagram.com/vidarzp/',
          socialIcon: InstagramIcon,
          socialName: 'instagram'
        },
        {
          socialLink: 'https://www.facebook.com/vidarzp',
          socialIcon: FacebookIcon,
          socialName: 'facebook'
        }
      ]
    }
  }
}
</script>
